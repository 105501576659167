export const environment = {
  production: false,
  WRAPPER_API: "https://abbott-ph-api-dev.sttarter.com/api/v1",
  // API_URl: "https://abbott-ph-api-dev.spurtreetech.com/api"
  // AUTH_BASE_URL: 'https://apac-s3wrapper.capillarytech.com',
  // API_BASE_URL: 'https://apac-apigateway.capillarytech.com/mobile/v2/api',
  // AUTH_BASE_URL: 'https://eu-auth-api.capillarytech.com',
  // API_BASE_URL: 'https://eu-api-gateway.capillarytech.com/',


  // Proxy URL's
  //AUTH_BASE_URL: 'https://cap-eu-auth-api-com.sttarter.com',
  AUTH_BASE_URL: 'https://eu-auth-api.capillarytech.com',
  API_BASE_URL: 'https://cap-eu-api-gw-com.sttarter.com/mobile/v2/api',
  IMAGE_GET: 'https://eu-api-gateway.capillarytech.com/mobile/v2/api',
  LICENSEKEY:'AQeCkCbLBGJdQp37fhMc7htFawpGLLavsWrllgFeU/F8AdD2qkpQ4DhrpSdzb7iQaUNLWGhIsa7CS47o3AxECA1+0BapbHO4XHL3/I9M+fPqTZd9e1EMt3Fj5p67FLSamGkl3oVDFc0DPZMRVTg3uqRFUnOSffB9j24H08tpm/M+TP2xmVCqJ1BbSWWmUWOFD1XJH7I6t8vOY6Msp375VGtXxmXBd4m9vF92BDdAaYL4SfvjqzFnvdttmiezSAiBlyix+xVWDY85SAGqyFRMrOh6hlarGa4MWFQeKwhxXOMmYWm+6QSiO5FVts6xbgMtwlDMSR9D6ly8TJw78DY3XrMlCwd2YjxZZ0qqN4g8j6dGaYCz7SHK//hO/N6OT0ioAGou7lwKnranfSaucCEwPoJ56OG3QgxLq2gmakRYZFJ2SSDjc2boYJ1dF/xtIQcWsg7wyFhVmcrkMUu1DAGUlXk8lCdlb5pw8UQuDpdibO4Bota194MaAFh6aWUEF46LdWYke13RkjgKtRk19ImCI3O3PzKDRf14dupgT1O/8x+IMMnd4e/+vNAlIelcdtyG9PjvmTATvEWXSyatczX8t2nPiXumF/BBM8BUbvsEXRMmiX35IwP3xxws5dKNoTxRZRROXrX8HOeryGu0IjU/Sqil0HLyw8Ov0mb4ck0sY9KYYm8+Dtd+VnLInMLRKyylyo+rR/lyQZPJluY5U/v4X6Umy+yhKyZMJ18BxkSn9R0hzfaxOv+ie/xN5jeErJOkZwABDlJb5HzjRu0D3XJXjZk92NR+AdgqrgS+vT6R8zsx9l24s3phhnMTNjAnIsB0APi6grGMPfeaPrapybdCK20FA4JFMlqPUu8kYUnafOJsepDzHJxWI0TzihrYfvhDZ+v9MDiauTYJed2VHWE1Fi02FjWJclC0NyJlrjpcoAPmn4GSnNs4+xpYBLfuYuA54jkM0MZVx9Ng1KAUgzAxW086L8SsJyPdZ0RXwfVh7opXs2z1yCctyX2BotBbmRuoV/h7VzA1XjwDV8IJYGitMmDFIUGE1tEybat1vyQH1DTvc7nQCV/m0ZSNqNoRTJqwSM3Yrae5+z/41hqCEkae7awMBZ+YfYOREWxcqpbSIbGP+jstP/yEE3luJKtDRyNzLx0HCgPZvPPuFg0fQD32Rg==',
  SALESFORCE_BASE_URL: ' https://abbott-ph-api-dev.sttarter.com/api/v1',
  CMS_URL:
    'https://api.sttarter.com/contentsystem/c2ff7690d4a451d462c01744dafa17d2',
  API_URl_TRACEBILITY: 'https://abbott-ph-api-dev.sttarter.com/api',
  IMAGE_UPLOAD: 'https://apac-apigateway.capillarytech.com/mobile/v2/',
  EMAIL_CHECK_API: 'https://abbott-ph-api-dev.sttarter.com/api/v1',
  STTARTER_GAMEPLAY_REWARD:
    'https://api.sttarter.com/contentsystem/a8130e57dbc58ffb8ee8774c201786cb',
  STTARTER_GAMEPLAY_FIRSTTIME_SCAN:
    'https://api.sttarter.com/contentsystem/a8130e57dbc58ffb8ee8774c201786cb/firsttimescanuat',
  STTARTER_GAMEPLAY_LUCKY_DRAW:
    'https://api.sttarter.com/contentsystem/a8130e57dbc58ffb8ee8774c201786cb/luckydrawuat',
  // GAME_MAP_API: "https://cap-intouch-in.sttarter.com",
  GAME_MAP_API: 'https://cap-apac-api-com.sttarter.com/v1.1',



  NUTRITION_CUST_ADD_URL: 'https://cap-intouch-in.sttarter.com',
  NUTRITION_TILL_ID: 'nutrition',
  OAUTH_TOKEN: 'https://cap-apac-api-com.sttarter.com/v3',
  SOCIAL_LOGIN_URL: 'https://cap-s3-apac.sttarter.com',


  BRAND: 'ABBOTTKUWAITUAT',
  DEVICE_ID: '1234551231',
  //DEVICE_ID: '12345',
  IDENTIFIER_TYPE: 'MOBILE',
  //TILL_ID: 'abbott.sg.demo.admin',
  TILL_ID: 'abbott.kuwait',
  COUNTRY_CODE: 'KW',
  LANGUAGE: 'en_US',
  MAXMOBNUM: 8,
  MINMOBNUM: 8,
  rewarddetail: 'details',
  ORG_ID: '100627',
  bugsnag_version: '19042023'
};


